import React from 'react'
import styled from '@emotion/styled'
import {
    FacebookShareButton,
    FacebookIcon,
    LinkedinShareButton,
    LinkedinIcon,
    TwitterShareButton,
    TwitterIcon
} from 'react-share'
import { IconContext } from 'react-icons'
import { FaInstagram, FaFacebook, FaTwitter } from 'react-icons/fa';

const List = styled.ul`
    position: sticky;
    top: 7.188rem;
    display: flex;
    flex-direction: column;

    li {
        svg {
            circle {
                fill: ${props => props.theme.colors.highlight};
                transition: ${props => props.theme.effects.transition};
            }
        }

        &:hover {
            svg {
                circle {
                fill: ${props => props.theme.colors.secondary};
                }
            }
        }
    }

`

const FooterList = styled.ul`
    display: flex;
    width: 100%;

    li {
        margin-right: 0.75rem;

        .footer-share-icons {
            color: ${props => props.theme.colors.white};
            transition: ${props => props.theme.effects.transition};
            
            &:hover {
                color: ${props => props.theme.colors.secondary};
            }
        }
    }
`

const SocialShareList = ({ iconSize, pageType }) => {
    const url = typeof window !== `undefined` ? window.location.href : '';

    return (
        pageType === 'footer' ?
            <FooterList>
                <li>
                    <a href="https://www.instagram.com/eklectish/" target="_blank">
                        <IconContext.Provider value={{ size: "1.5em", className: 'footer-share-icons' }}>
                            <FaInstagram />
                        </IconContext.Provider>
                    </a>
                </li>

                <li>
                    <a href="https://www.facebook.com/Eklectish-102752941473189/" target="_blank">
                        <IconContext.Provider value={{ size: "1.5em", className: 'footer-share-icons' }}>
                            <FaFacebook />
                        </IconContext.Provider>
                    </a>
                </li>

                <li>
                    <a href="https://twitter.com/eklectish" target="_blank">
                        <IconContext.Provider value={{ size: "1.5em", className: 'footer-share-icons' }}>
                            <FaTwitter />
                        </IconContext.Provider>
                    </a>
                </li>
            </FooterList>
        :
            <List>
                <li>
                    <FacebookShareButton url={url}>
                        <FacebookIcon size={iconSize} round={true} />
                    </FacebookShareButton>
                </li>

                <li>
                    <LinkedinShareButton url={url}>
                        <LinkedinIcon size={iconSize} round={true} />
                    </LinkedinShareButton>
                </li>

                <li>
                    <TwitterShareButton url={url}>
                        <TwitterIcon size={iconSize} round={true} />
                    </TwitterShareButton>
                </li>
            </List>
    )
}

export default SocialShareList;
