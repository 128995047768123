import React from 'react';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import { showMobileMenu, hideMobileMenu } from '../../actions/displayChanges';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'

const Button = styled.button`
    padding: 0.188rem;
    color: ${props => props.theme.colors.white};
    position: absolute;
    top: 18%;
    right: 0.625rem;
    
    @media (max-width: 400px) {
        top: 8%;
    }
`

const MobileMenuButton = () => {
    const dispatch = useDispatch();

    const modalDisplay = useSelector(state => state.displayChanges[0].showMobileMenu);

    const toggleMobileMenu = () => {
        if (!modalDisplay) {
            dispatch(showMobileMenu());
        } else {
            dispatch(hideMobileMenu());
        }
    };

    return (
        <Button onClick={toggleMobileMenu}>
            <FontAwesomeIcon icon={faBars} size="2x" color="#fff" />
        </Button>
    )
};

export default MobileMenuButton;