export const testAction = (str) => ({
    type: 'TEST_ACTION',
    property: str
});

export const showMobileMenu = () => ({
    type: 'SHOW_MOBILE_MENU',
});

export const hideMobileMenu = () => ({
    type: 'HIDE_MOBILE_MENU',
});

export const showMobileMenuButton = () => ({
    type: 'SHOW_MOBILE_MENU_BUTTON',
})

export const hideMobileMenuButton = () => ({
    type: 'HIDE_MOBILE_MENU_BUTTON',
})

export const hideIntroCTA = () => ({
    type: 'HIDE_INTRO_CTA',
})

export const incrementExpandedFAQ = () => ({
    type: 'INCREMENT_EXPANDED_FAQ'
})

export const decrementExpandedFAQ = () => ({
    type: 'DECREMENT_EXPANDED_FAQ'
})

export const setAllExpandedFAQ = (faqDataLength) => ({
    type: 'SET_ALL_EXPANDED_FAQ',
    property: faqDataLength,
})

export const resetExpandedFAQ = () => ({
    type: 'RESET_EXPANDED_FAQ'
})