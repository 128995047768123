import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import { useSiteMetadata } from '../../hooks/use-site-metadata'
import MobileMenuButton from './MobileMenuButton';
import SearchBarNav from '../Search/SearchBarMenu';
import { showMobileMenuButton, hideMobileMenuButton, hideMobileMenu } from '../../actions/displayChanges';
import logo from "../../assets/logos/eklectish-logo-primary-white-adjusted.png";
import { useScrollPosition } from '../../hooks/useScrollPosition';

const Header = styled.header`
  background: ${props => props.theme.colors.primary};
  width: 100%;
  padding: 1.5rem 0;
  top: 0;
  position: fixed;
  z-index: 10;
  transition: all 0.3s linear;
  box-shadow: 0px 0.125rem 0.125rem 0px rgba(0,0,0,0.5);

  @media (min-width: 767px) {
    &.active-scroll {
      img {
        width: 225px;
      }

      a {
        font-size: 1.25rem;
      }
    }
  }

  @media (max-width: 767px) {
    position: unset;
  }
`
const Nav = styled.nav`
  width: 100%;
  max-width: ${props => props.theme.sizes.maxWidth};
  margin: 0 auto;
  padding: 0 1.5rem;
  position: relative;

  @media (max-width: 400px) {
    padding: 0 .5rem;
  }

  @media (min-width: 767px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ul {
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 766px) {
      justify-content: center;
      flex-wrap: wrap;
    }
  }

  li {
    display: inline-block;
    margin-left: 1rem;

    &:not(:first-of-type) {
      position: relative;

      @media (max-width: 766px) {
        display: none;
      }
      
      &::after {
        content: '';
        width: 100%;
        height: 0.125rem;
        max-width: 0;
        position: absolute;
        bottom: -0.25rem;
        left: 0;
        transition: max-width 0.2s ease-in;
        background-color: ${props => props.theme.colors.secondary};
      }

      &:hover {
        &::after {
          max-width: 100%;
        }
      }
    }

    &:first-of-type {
      position: relative;
      margin: 0;
      align-self: center;

      a {
        @media (max-width: 766px) {
          display: flex;
          justify-content: center;
        }
      }
    }
  }
`

const StyledLink = styled(Link)`
  text-decoration: none;
  color: DarkGray;
  font-weight: 200;
  transition: all 0.3s;
  color: ${props => props.theme.colors.white};
  font-family: ${props => props.theme.fonts.navLinks};
  font-size: 24px;

  img {
    width: 350px;
    transition: width 0.3s linear;

    @media (max-width: 767px) {
      height: 100%;
    }

    @media (max-width: 600px) {
      width: 275px;
    }
    
    @media (max-width: 400px) {
      width: 225px;
    }
  } 
`

const activeLinkStyle = {
  color: 'white',
}

const Menu = () => {
  const { menuLinks } = useSiteMetadata();

  const dispatch = useDispatch();
  const mobileMenuButtonDisplay = useSelector(state => state.displayChanges[0].showMobileMenuButton);

  const displayMobileMenuAtMobileScreenWidths = () => {
    if (window.innerWidth < 767) {
      dispatch(showMobileMenuButton());
    } else {
      dispatch(hideMobileMenuButton());
      dispatch(hideMobileMenu());
    }
  }

  useEffect(() => {
    displayMobileMenuAtMobileScreenWidths();
    window.addEventListener('resize', displayMobileMenuAtMobileScreenWidths);
  }, [])

  const setMenuItem = (menuItemName) => {
    return menuItemName === 'Home' ?
    <img src={logo} alt="Eklectish Logo directing back to Home Page" />
    :
    menuItemName;
  }

  const setClassBasedOnScrollPosition = () => {
    const scrollPosition = useScrollPosition();

    return scrollPosition > 25.5 ? 'active-scroll' : '';
  }

  return (
    <Header className={setClassBasedOnScrollPosition()}>
      <Nav>
        <ul>
          {menuLinks.map(link => (
            <li key={link.name}>
              <StyledLink to={link.slug} activeStyle={activeLinkStyle}>
                {setMenuItem(link.name)}
              </StyledLink>
            </li>
          ))}
        </ul>
        {mobileMenuButtonDisplay ? <MobileMenuButton /> : null}
        <SearchBarNav />
      </Nav>
    </Header>
  )
}

export default Menu
