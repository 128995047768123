import React from 'react'
import styled from '@emotion/styled'

const Wrapper = styled.section`
  max-width: ${props => props.theme.sizes.maxWidth};
  padding: 2rem 1.5rem;
  flex-grow: 1;
  margin: 0 auto;

  @media (max-width: 767px) {
    padding-top: 1rem;
  }

  &.content-page {
    width: 60%;
    margin: 0;
    padding: 0 1.5rem 4.75rem;

    @media (max-width: 767px) {
      padding: 2rem 1.5rem;
    }

    @media (max-width: 400px) {
      padding: 2rem .5rem 2rem;
    }
    
    .rhap_container {
      max-width: 850px;
      margin: 0 auto 1.875rem;
    }

    &[class*="746cdb6c-b781-56e2-939c-2e66667a7418"] {
      > ul {
        + div {
          p:first-of-type {
            font-size: 1.5rem;
            text-align: center;
          }
        }
      }
    }

    &[class*="9e73ebbc-11bc-5a2a-a089-82df25d1b32c"] {
      > ul {
        + div {
          p:nth-of-type(2),
          p:nth-of-type(3),
          p:nth-of-type(4),
          p:nth-of-type(5),
          p:nth-of-type(6),
          p:nth-of-type(7) {
            font-size: 1.5rem;
            text-align: center;
          }
        }
      }
    }
  }
`

const Container = props => {
  return (
    props.contentPage ? 
    <Wrapper className={`content-page ${props.id}`}>{props.children}</Wrapper>
    :
    <Wrapper>{props.children}</Wrapper>
  )
}

export default Container
