import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled'
import { navigate } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { updateSearchValue } from '../../actions/valueChanges';

const SearchBarContainer = styled.div`
    position: absolute;
    right: 0;

    @media (max-width: 766px) {
        display: none;
    }
`

const SearchForm = styled.form`
    display: inline-flex;
    position: relative;

    svg.fa-search {
        display: inline-block;
        position: relative;
        font-size: 2.25rem;
        padding: .5rem;
        border-radius: 1.25rem; 
        transition: all 0.5s ease-in;
    }

    .input-wrapper {
        background-color: transparent;
        border-radius: 1rem;
        position: relative;
        right: -.925rem;
        transition: max-width 0.2s ease-in, background-color 0.2s ease-in;
        max-width: 0;
    }

    input[type='text'] {
        border-bottom: solid 1px #000;
        color: #000;
        font-family: ${props => props.theme.fonts.copy};
        opacity: 0;
        transition: max-width 0.2s ease-in, opacity 0.1s ease-in;
        max-width: 0;
    }

    label {
        z-index: 2;
    }

    button {
        padding-left: 0;
        padding-right: 0;
        position: absolute;
        right: 0;
        z-index: -1;

        svg {
            opacity: 0;
            transition: all 0.3s ease-in;
        }
    }

    &.search-focus,
    &:focus,
    &:focus-within {
        .input-wrapper {
            align-self: center;
            background-color: #fff;
            padding: .25rem 1rem;
            max-width: 200px;
        }

        input[type='text'] {
            opacity: 100%;
            max-width: 150px;
            padding: .125rem .625rem .125rem 0;
        }

        svg {
            background-color: ${props => props.theme.colors.highlight};

            path {
                fill: #000;
            }
        }

        label {
            z-index: -1;

            svg {
                opacity: 0;
            }
        }

        button {
            z-index: 2;

            svg {
                opacity: 100%;
            }
        }
    }
`

const SearchBarNav = () => {
    const dispatch = useDispatch();
    const [searchValue, setSearchValue] = useState("");
    const urlLocation = typeof window !== "undefined" ? window.location : "";

    const handleSubmit = (e) => {
        e.preventDefault();

        if (searchValue === "") {
            return;
        }

        dispatch(updateSearchValue(searchValue));
        navigate(`/search`);
    }

    const handleInput = (e) => {
        const value = e.target.value;
        const form = e.target.parentElement.parentElement;

        if (value) {
            form.classList.add("search-focus");
            setSearchValue(value);
            return;
        }

        form.classList.remove("search-focus");
    }

    return (
        urlLocation.search ?
            null
        :
            <SearchBarContainer>
                <SearchForm action="">
                    <div className="input-wrapper">
                        <input type="text" id="Search" placeholder="Search" onChange={handleInput} />
                    </div>
                    <label htmlFor="Search">
                        <span className="visually-hidden">Search</span>
                        <FontAwesomeIcon icon={faSearch} size="1x" color="#fff" />
                    </label>
                    <button
                        onClick={handleSubmit}>
                        <span className="visually-hidden">Submit</span>
                        <FontAwesomeIcon icon={faSearch} size="1x" color="#fff" />
                    </button>
                </SearchForm>
            </SearchBarContainer>
    )
}

export default SearchBarNav;