import { useEffect, useState } from 'react'


export const useScrollPosition = () => {
    const [scroll, setScroll] = useState(0);

    const handleScroll = () => {
        const position = typeof window !== `undefined` ? window.pageYOffset : 0;
        setScroll(position);
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return scroll;
};