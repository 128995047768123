import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import { useSiteMetadata } from '../../hooks/use-site-metadata'
import { hideMobileMenu } from '../../actions/displayChanges';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import logo from "../../assets/logos/eklectish-logo-primary-white-adjusted.png";

const MobileHeader = styled.header`
    background: ${props => props.theme.colors.primary};
    width: 100%;
    padding: 1.5rem 0;
    height: 100vh;
    position: absolute;
    z-index: 10;
    right: 0;
    animation: fadein 1s;

    @keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
    }       
`
const Nav = styled.nav`
    width: 100%;
    margin: 0 auto;
    padding: 0 1.5rem;

    ul {
        height: 100vh;
        position: relative;

        button {
            color: white;
            position: absolute;
            right: -0.9rem;
            top: 0.313rem;
        }

        aside {
            display: flex;
            flex-direction: column;
            align-items: center;
            max-width: 350px;
            margin: 0 auto;
            padding-top: 30vh;
        }
    }
`

const MobileItem = styled.li`
    display: inline-block;
    
    &:first-of-type {
        position: relative;
        margin: 0;
        margin-bottom: 1rem;

        img {
            max-width: 350px;
        }
    }

    &:not(:first-of-type) {
        margin-bottom: 2rem;
    }
`

const MobileLink = styled(Link)`
    text-decoration: none;
    color: white;
    font-weight: 600;
    transition: all 0.2s;
    font-family: ${props => props.theme.fonts.navLinks};
    font-weight: 200;
    font-size: 2rem;
    
    &:hover {
        color: white;
    }
`

const activeLinkStyle = {
    color: 'white',
}

const MobileMenu = () => {
    const { mobileMenuLinks } = useSiteMetadata();

    const dispatch = useDispatch();
    const mobileMenuDisplay = useSelector(state => state.displayChanges[0].showMobileMenu);

    const hideMobileMenuDisplay = () => {
        dispatch(hideMobileMenu());
    }

    const mobileMenuDisplayClassName = () => {
        let className = ''
        if (mobileMenuDisplay) {
            className = 'mobile-menu-active';
        }
        return className;
    }

    const setMenuItem = (menuItemName) => {
        return menuItemName === 'Home' ?
        <img src={logo} alt="Eklectish Logo directing back to Home Page" />
        :
        menuItemName;
    }

    return (
        mobileMenuDisplay ? 
            <MobileHeader >
                <Nav>
                    <ul>
                        <button onClick={hideMobileMenuDisplay}>
                            <FontAwesomeIcon icon={faTimes} size="2x" color="#fff" />
                        </button>
                        <aside className="mobile-items">
                            {mobileMenuLinks.map(link => (
                                <MobileItem key={link.name}>
                                    <MobileLink to={link.slug} 
                                    activeStyle={activeLinkStyle} 
                                    onClick={hideMobileMenuDisplay}
                                    >
                                        {setMenuItem(link.name)}
                                    </MobileLink>
                                </MobileItem>
                            ))}
                        </aside>
                    </ul>
                </Nav>
            </MobileHeader>
        :
        null
    )
}

export default MobileMenu
