import React from 'react'
import styled from '@emotion/styled'
import { useSiteMetadata } from '../../hooks/use-site-metadata'
import { Link } from 'gatsby'
import SocialShareList from '../SocialShare/SocialShareList';
import logo from "../../assets/logos/eklectish-logo-primary-white-adjusted.png";

const Wrapper = styled.footer`
  width: 100%;
  background: ${props => props.theme.colors.primary};
  padding: 2rem 0;

  @media (max-width: 767px) {
    padding-bottom: 1.25rem;
  }
  `
const Container = styled.div`
    width: 90%;
    max-width: ${props => props.theme.sizes.maxWidth};
    margin: 0 auto;
    padding: 0 1.5rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    ul:first-of-type {
      width: 50%;

      img {
        @media (min-width: 500px) {
          margin-left: -0.25rem;
        }
      }
    }

    ul:nth-of-type(2) { 
      li {
        margin-bottom: 0.625rem;
      }
    }

    @media (max-width: 500px) {
      width: 100%;
      padding: 1rem 0 0 0;
      justify-content: center;

      ul:first-of-type {
        width: 100%;

        li {
          margin: 0 auto;
        }
      }

      ul:nth-of-type(2) {
        li {
          text-align: center;
        }
      }
    }
`
  
  const List = styled.ul`
  display: flex;
  flex-flow: column wrap;
  align-items: flex-start;
  padding: 1rem 0 2rem;
`

const Item = styled.li`
  display: inline-block;
  padding: 0.25rem 0;
  width: 100%;
  max-width: 200px;

  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    width: auto;
  }
`

const StyledLink = styled(Link)`
  transition: ${props => props.theme.effects.transition};
  color: ${props => props.theme.colors.white};
  font-family: ${props => props.theme.fonts.navLinks};
  font-weight: 300;
  font-size: 1.25rem;
  text-decoration: none;
  position: relative;

  &::after {
    content: '';
    width: 100%;
    height: 0.125rem;
    max-width: 0;
    position: absolute;
    bottom: -.25rem;
    left: 0;
    transition: max-width 0.2s ease-in;
    background-color: ${props => props.theme.colors.secondary};
  }

  &:hover {
    &::after {
      max-width: 100%;
    }
  }
`

const FooterMast = styled.p`
  width: 100%;
  font-size: 1rem;
  text-align: center;
  color: ${props => props.theme.colors.white};
  margin: 0;
  padding: 1.3rem 0;
  font-family: ${props => props.theme.fonts.highlight};
  
  @media (max-width: 500px) {
    width: 90%;
    margin: 0 auto;
  }
`

const Footer = () => {
  const { footerLinks } = useSiteMetadata();
  
  return (
    <Wrapper>
      <Container>
        <List>
          <Item>
            <Link to={`/`} >
              <img src={logo} alt="Eklectish Logo directing back to Home Page" />
            </Link>
          </Item>
          <Item>
            <SocialShareList iconSize={40} pageType={'footer'} />
          </Item>
        </List>
        <List>
          {footerLinks.map(link => (
            <Item key={link.name}>
              <StyledLink to={link.slug}>{link.name}</StyledLink>
            </Item>
          ))}
        </List>
      </Container>
    </Wrapper>
  )
} 

export default Footer


{/* <button onClick={testActionFunction}>Add</button>
              <a
                href="https://www.contentful.com/"
                rel="nofollow noopener noreferrer"
                target="_blank"
              >
                <img
                  src="https://images.ctfassets.net/fo9twyrwpveg/44baP9Gtm8qE2Umm8CQwQk/c43325463d1cb5db2ef97fca0788ea55/PoweredByContentful_LightBackground.svg"
                  style={{ width: '100px' }}
                  alt="Powered by Contentful"
                />
              </a> */}


{/* <Item>
            <a
              href="https://github.com/ryanwiemer/gatsby-starter-gcn"
              target="_blank"
              rel="noopener noreferrer"
            >
              gatsby-starter-gcn
            </a>{' '}
            by{' '}
            <a
              href="https://github.com/ryanwiemer"
              target="_blank"
              rel="noopener noreferrer"
            >
              @ryanwiemer
            </a>
          </Item> */}