import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import styled from '@emotion/styled'
import { Global } from '@emotion/core'
import Menu from './HeaderFooter/Menu'
import MobileMenu from '../components/HeaderFooter/MobileMenu';
import Footer from './HeaderFooter/Footer'
import { globalStyles } from '../styles/globalStyles.js'

const Root = styled.div`
  &.mobile-menu-active {
    overflow: hidden;
  }
`

const Skip = styled.a`
  font-family: ${props => props.theme.fonts.body};
  padding: 0 1rem;
  line-height: 3.125rem;
  background: #2867cf;
  color: white;
  z-index: 101;
  position: fixed;
  top: -100%;

  &:hover {
    text-decoration: underline;
  }
  
  &:focus,
  &:active,
  &:hover {
    top: 0;
  }
`

const Main = styled.div`
  background-color: ${props => props.theme.colors.background};
  min-height: 70vh;
  padding-bottom: 3.125rem;

  @media (min-width: 767px) {
    padding-top: 1.75rem;
  }
`

const Layout = props => {
  function handleFirstTab(e) {
    if (e.keyCode === 9) {
      document.body.classList.add('user-is-tabbing')
    }
  }
  useEffect(() => window.addEventListener('keydown', handleFirstTab), [])

  const mobileMenuActive = 
  useSelector(state => state.displayChanges[0].showMobileMenu) ? 
  'mobile-menu-active'
  :
  '';

  return (
    <Root className={`siteRoot ${mobileMenuActive}`}>
      <div className="siteContent">
        <Skip href="#main" id="skip-navigation">
          Skip to content
        </Skip>
        <Menu />
        <MobileMenu />
        <Main id="main">{props.children}</Main>
        {/* <Main>{props.children}</Main> */}
      </div>
      <Footer />
      <Global styles={globalStyles} />
    </Root>
  )
}

export default Layout
